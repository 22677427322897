<template>
  <div
    class="mobile-margin"
  >
    <div style="display: none;">
      <div
        id="pin"
        class="text-center"
      >
        <v-icon
          v-blur
          x-large
          color="red"
        >
          mdi-map-marker
        </v-icon>
      </div>
    </div>
    <v-dialog
      v-model="requestLimitReached"
      persistent
      max-width="350"
    >
      <v-card>
        <div class="text-right me-1">
          <v-icon
            color="red"
            size="28"
            @click="resetDrawer()"
          >
            mdi-close
          </v-icon>
        </div>
        <v-card-title class="text-h5">
          {{ $t('TicketingLimit.title') }}
        </v-card-title>
        <v-card-text>
          {{ $t('TicketingLimit.description') }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            to="/contacts"
            color="green darken-1"
            text
          >
            {{ $t('NavigationSideBar.contacts') }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="$router.push({
              name: 'TicketingRequestList',
              params: { id: service_id }
            })"
          >
            {{ $t('TicketingTable.title') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>

    <v-card
      id="rightBar"
      class="top-component-class pa-0 ma-0"
    >
      <v-card-title
        class="ticketing-card-title ma-0 d-flex justify-space-between"
        color="black"
      >
        {{ $t('Ticketing.title') }}
        <!-- <v-icon
          color="red"
          size="30"
          @click="resetDrawer()"
        >
          mdi-close
        </v-icon> -->
      </v-card-title>
      <div class="slider">
        <v-form
          v-model="valid"
          lazy-validation
        >
          <v-card-text :class="!mobileBreakpoints() ? 'mt-2' : ''">
            <span class="ticketing-card-subtitle">
              <b>{{ $t('Ticketing.select') }}</b>
            </span>
            <v-row>
              <v-col
                cols="12"
                md="6"
                class="d-flex pb-0"
              >
                <v-select
                  v-model="selectedDocumentTypes"
                  :items="documentTypesList"
                  :item-text="item => $t(`ServiceCard.${ticket_id}.${item.document_id}.documentType`)"
                  :label="$t('Ticketing.documentType') + '*'"
                  prepend-icon="mdi-file-document-edit-outline"
                  return-object
                  @change="disableMarker()"
                >
                  <!-- <template v-slot:item="{ item }">
                    {{ $t(item) }}
                  </template>
                  <template v-slot:selection="{ item }">
                    {{ $t(item) }}
                  </template> -->
                </v-select>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      class="ml-2"
                      color="green darken-1"
                      v-on="on"
                    >
                      mdi-information-variant-circle-outline
                    </v-icon>
                  </template>
                  <div
                    class="my-1"
                    :style="{ 'max-width': '400px' }"
                  >
                    <span v-if="selectedTooltipDataDocument.title">
                      <b>{{ selectedTooltipDataDocument.title }}:</b> <br> {{ selectedTooltipDataDocument.text }}
                    </span>
                    <span v-else>
                      {{ tooltipNoSelection }}
                    </span>
                  </div>
                </v-tooltip>
              </v-col>
              <v-col
                cols="12"
                md="6"
                class="d-flex pb-0"
              >
                <v-select
                  v-model="selectedMeteoTypes"
                  :items="meteoTypesList"
                  :item-text="item => $t(`ServiceCard.${ticket_id}.${item.meteo_id}.meteoType`)"
                  :label="$t('Ticketing.meteoType') + '*'"
                  prepend-icon="mdi-cloud-outline"
                  return-object
                  @change="updateMeteoType()"
                >
                  <!-- <template v-slot:item="{ item }">
                    {{ $t(item) }}
                  </template>
                  <template v-slot:selection="{ item }">
                    {{ $t(item) }}
                  </template> -->
                </v-select>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      class="ml-2"
                      color="green darken-1"
                      v-on="on"
                    >
                      mdi-information-variant-circle-outline
                    </v-icon>
                  </template>
                  <div
                    class="my-1"
                    :style="{ 'max-width': '400px' }"
                  >
                    <span v-if="selectedTooltipDataMeteo.title">
                      <b>{{ selectedTooltipDataMeteo.title }}:</b>
                      <!-- eslint-disable-next-line vue/no-v-html -->
                      <span v-html="formatTooltipText(selectedTooltipDataMeteo.text)" />
                    </span>
                    <span v-else>
                      {{ tooltipNoSelection }}
                    </span>
                  </div>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-menu
              ref="rangeMenu"
              v-model="rangeMenu"
              :close-on-content-click="false"
              :return-value.sync="rangeDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="rangeDateText"
                  multiple
                  chips
                  small-chips
                  :label="$t('Ticketing.rangeDate') + '*'"
                  prepend-icon="mdi-calendar"
                  readonly
                  :disabled="Object.keys(selectedMeteoTypes).length === 0"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="rangeDate"
                no-title
                range
                scrollable
                :min="rangeDate[0]"
                :max="getCalendarMaxDate()"
                :locale="app_user.app_user_data[0].language"
                first-day-of-week="1"
              >
                <div
                  class="d-flex flex-column mx-auto"
                  style="width: 100%"
                >
                  <div
                    v-if="!isRelazione"
                    class="d-flex justify-center caption text--secondary"
                    style="height: 20px"
                  >
                    <v-icon size="18">
                      mdi-information-variant
                    </v-icon>
                    <p>
                      {{ calendarInfoText }}
                    </p>
                  </div>
                  <div class="d-flex justify-space-between">
                    <v-btn
                      text
                      color="primary"
                      @click="closeDateRange()"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      :disabled="rangeDate.length < 2"
                      @click="$refs.rangeMenu.save(rangeDate)"
                    >
                      OK
                    </v-btn>
                  </div>
                </div>
              </v-date-picker>
            </v-menu>
            <div class="mt-4 ticketing-card-subtitle">
              <b>{{ $t('Ticketing.geo') }}</b>
            </div>
            <div v-if="!showMaxLimitMsg">
              <v-radio-group
                v-model="selectedAddressType"
                row
                mandatory
                @change="resetAddress()"
              >
                <v-radio
                  :label="$t('Ticketing.address')"
                  :ripple="true"
                  value="Address"
                  color="#0da344"
                />
                <v-radio
                  v-if="isRelazione || isPuntuale"
                  :label="$t('Ticketing.marker') "
                  :ripple="true"
                  value="Manual"
                  color="#0da344"
                  @click="isManualCoordinates = true"
                />
                <v-tooltip
                  v-if="isManualCoordinates"
                  bottom
                >
                  <template v-slot:activator="{ on }">
                    <v-icon
                      class="ml-2"
                      color="green darken-1"
                      v-on="on"
                    >
                      mdi-information-variant-circle-outline
                    </v-icon>
                  </template>
                  <div
                    class="my-1"
                    :style="{ 'max-width': '400px' }"
                  >
                    <span>
                      {{ $t('Ticketing.tooltipCoordinate') }}
                    </span>
                  </div>
                </v-tooltip>
              </v-radio-group>
              <div v-if="selectedAddressType === 'Address'">
                <v-autocomplete
                  v-if="isComunale || isProvinciale"
                  v-model="selectedAddressFromSelect"
                  class="ma-0"
                  :items="ticketing.comuniProvince"
                  label="Seleziona un comune o una provincia"
                  :disabled="Object.keys(selectedMeteoTypes).length === 0"
                  @change="getCoordinatesFromAddress()"
                />
                <input
                  v-else
                  ref="autocomplete"
                  :placeholder="$t('Ticketing.searchBox')"
                  class="search-location"
                  :disabled="Object.keys(selectedMeteoTypes).length === 0"
                  onfocus="value = ''"
                  type="text"
                  @click="autocompleteInit()"
                >
              </div>
              <v-row v-else>
                <v-col
                  cols="6"
                  class="pb-0"
                >
                  <v-text-field
                    id="lat"
                    v-model="latitude"
                    :label="$t('Ticketing.latitude')"
                    :rules="number_rules"
                    @keyup="map.address = ''; map.addressProv = ''"
                  />
                </v-col>
                <v-col
                  cols="6"
                >
                  <v-text-field
                    id="lng"
                    v-model="longitude"
                    :label="$t('Ticketing.longitude')"
                    :rules="number_rules"
                    @keyup="map.address = ''; map.addressProv = ''"
                  />
                </v-col>
              </v-row>
              <v-text-field
                v-if="!isComunale && !isProvinciale"
                v-model="placeName"
                :label="$t('Ticketing.address') + '*'"
                :disabled="true"
              />
              <div class="d-flex flex-column align-start">
                <v-btn
                  v-if="selectedAddressType === 'Manual'"
                  id="submit"
                  color="#0da344"
                  small
                  style="color:white"
                  :disabled="disableFindAddress"
                  @click="dispatchManualGeocoderMarker()"
                >
                  {{ $t('Ticketing.findAddress') }}
                </v-btn>
                <v-btn
                  color="#0da344"
                  small
                  style="color:white"
                  class="my-2"
                  :disabled="checkForm()"
                  @click="addLocation()"
                >
                  {{ $t('Ticketing.addLocation') }}
                </v-btn>
              </div>
            </div>
            <v-alert
              :value="showMaxLimitMsg"
              dense
              type="warning"
              icon="mdi-alert-circle-outline"
              class="mt-4"
              transition="scale-transition"
            >
              {{ $t('Ticketing.maxPointsLimit') }}
            </v-alert>
            <v-data-table
              id="locations-table"
              :headers="ticketing.headers"
              :items="locationItems"
              :mobile-breakpoint="0"
              :footer-props="{
                itemsPerPageAllText: $t('Table.all'),
                itemsPerPageText: $t('Table.rowsPerPage'),
                pageText: `{0}-{1} ${$t('Table.of')} {2}`
              }"
              :no-data-text="$t('Table.noData')"
            >
              <template
                v-for="h in ticketing.headers"
                v-slot:[`header.${h.value}`]="{ header }"
              >
                {{ $t(header.text) }}
              </template>
              <template
                v-slot:[`item.start_date`]="{ item }"
              >
                <span>
                  {{ item.start_date ? moment(item.start_date).format('DD-MM-YYYY') : item.start_date }}
                </span>
              </template>
              <template
                v-slot:[`item.end_date`]="{ item }"
              >
                <span>
                  {{ item.end_date ? moment(item.end_date).format('DD-MM-YYYY') : item.end_date }}
                </span>
              </template>
              <template
                v-slot:[`item.latitude`]="{ item }"
              >
                <span>
                  {{ parseFloat(item.latitude).toFixed(2) }}
                </span>
              </template>
              <template
                v-slot:[`item.longitude`]="{ item }"
              >
                <span>
                  {{ parseFloat(item.longitude).toFixed(2) }}
                </span>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <delete
                  :id="item.name"
                  @deleted="deleteItem(item)"
                />
              </template>
              <template v-slot:[`item.meteo_type`]="{ item }">
                {{ splitMeteoOrDocumentType(item.meteo_type) }}
              </template>
            </v-data-table>
            <ticketing-priority-selection
              v-if="checkRequestManual && !isRelazione"
              :required="isReport"
              :evasion-limit="evasion_limit"
              :location-items="locationItems"
              :is-provinciale="isProvinciale"
              @update-evasion-limit="updateEvasionLimit($event)"
              @can-be-moved="canBeMoved($event)"
            />
            <document-s3-table
              :ticketing-path="true"
              :disable-form="false"
              :request-type="selectedMeteoTypes.is_auto ? 'A' : 'M'"
            />
            <div class="d-flex align-center">
              <div class="ticketing-card-subtitle pr-4">
                <b>{{ $t('Ticketing.note') }}</b>
              </div>

              <v-tooltip
                v-if="selectedMeteoTypes.is_auto"
                right
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    color="green darken-1"
                    v-on="on"
                  >
                    mdi-information-variant-circle-outline
                  </v-icon>
                </template>
                <div
                  class="my-1"
                  :style="{ 'max-width': '400px' }"
                >
                  <span>
                    {{ $t('Ticketing.tooltipNote') }}
                  </span>
                </div>
              </v-tooltip>
            </div>

            <v-textarea
              v-model="notes"
              class="mt-2"
              :counter="selectedMeteoTypes.is_auto ? 100 : 512"
              :maxlength="selectedMeteoTypes.is_auto ? 100 : 512"
              auto-grow
            />
            <v-alert
              :value="alert"
              color="red accent-4"
              dark
              transition="scale-transition"
            >
              extension file not allowed, please insert <b>.pdf</b>
            </v-alert>
            <div
              v-if="confirmIsDisabled"
              class="mt-4 error--text"
            >
              {{ $t('Ticketing.obligatoryMessage') }}
            </div>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              color="#0da344"
              style="color:white"
              :disabled="confirmIsDisabled"
              @click="confirmRequest()"
            >
              {{ $t('Ticketing.confirm') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { getRegExp } from '@/formRules.js';
import { v4 as uuidv4 } from 'uuid';
import { fromLonLat, toLonLat } from 'ol/proj';
import Overlay from 'ol/Overlay';
import moment from 'moment-business-days';

export default {
  components: {
    DocumentS3Table: () => import('@/components/utils/DocumentS3Table'),
    delete: () => import('@/components/utils/Delete'),
    TicketingPrioritySelection: () => import('@/components/layout/TicketingPrioritySelection')
  },
  data() {
    return {
      ticket_id: '',
      documentTypesList: [],
      meteoTypesList: [],
      pin: null,
      autocomplete: null,
      newMap: null,
      country: '',
      latitude: '',
      longitude: '',
      notes: '',
      location_id: '',
      service_id: '',
      evasion_limit: '',
      selectedAddressFromSelect: '',
      selectedAddressType: 'Address',
      valid: true,
      alert: false,
      rangeMenu: false,
      overlay: false,
      requestLimitReached: false,
      isManualCoordinates: false,
      requestCanBeMoved: false,
      showMaxLimitMsg: false,
      selectedDocumentTypes: {},
      selectedMeteoTypes: {},
      number_rules: [...getRegExp('two_numbers_dot_only')],
      custom_rules: [],
      meteoTypes: [],
      rangeDate: [],
      selected: [],
      locationItems: [],
      checkRelazione: null,
      tooltipNoSelection: this.$t('Ticketing.tooltipNoSelection'),
      tooltipDataDocument: {
        relazione: {
          title: this.$t('Ticketing.relazione'),
          text: this.$t('Ticketing.tooltipRelazione')
        },
        comunale: {
          title: this.$t('Ticketing.comunale'),
          text: this.$t('Ticketing.tooltipComunale')
        },
        puntuale: {
          title: this.$t('Ticketing.puntuale'),
          text: this.$t('Ticketing.tooltipPuntuale')
        },
        provinciale: {
          title: this.$t('Ticketing.provinciale'),
          text: this.$t('Ticketing.tooltipProvinciale')
        }
      },
      tooltipDataMeteo: {
        colpoDiSole: {
          title: this.$t('Ticketing.colpoDiSole'),
          text: this.$t('Ticketing.tooltipColpoDiSole')
        },
        eccessoDiNeve: {
          title: this.$t('Ticketing.eccessoDiNeve'),
          text: this.$t('Ticketing.tooltipEccessoDiNeve')
        },
        eccessoDiPioggia: {
          title: this.$t('Ticketing.eccessoDiPioggia'),
          text: this.$t('Ticketing.tooltipEccessoDiPioggia')
        },
        fulminazione: {
          title: this.$t('Ticketing.fulminazione'),
          text: this.$t('Ticketing.tooltipFulminazione')
        },
        geloEBrina: {
          title: this.$t('Ticketing.geloEBrina'),
          text: this.$t('Ticketing.tooltipGeloEBrina')
        },
        sbalzoTermico: {
          title: this.$t('Ticketing.sbalzoTermico'),
          text: this.$t('Ticketing.tooltipSbalzoTermico')
        },
        ondataDiCalore: {
          title: this.$t('Ticketing.ondataDiCalore'),
          text: this.$t('Ticketing.tooltipOndataDiCalore')
        },
        ventoForte: {
          title: this.$t('Ticketing.ventoForte'),
          text: this.$t('Ticketing.tooltipVentoForte')
        },
        ventoCaldo: {
          title: this.$t('Ticketing.ventoCaldo'),
          text: this.$t('Ticketing.tooltipVentoCaldo')
        },
        grandine: {
          title: this.$t('Ticketing.grandine'),
          text: this.$t('Ticketing.tooltipGrandine')
        },
        siccità: {
          title: this.$t('Ticketing.siccità'),
          text: this.$t('Ticketing.tooltipSiccità')
        },
        siccitàSpei3: {
          title: this.$t('Ticketing.siccitàSpei3'),
          text: this.$t('Ticketing.tooltipSiccitàSpei3')
        },
        siccitàPgra2023: {
          title: this.$t('Ticketing.siccità'),
          text: this.$t('Ticketing.tooltipSiccità')
        },
        neveGiornaliera: {
          title: this.$t('Ticketing.neveGiornaliera'),
          text: this.$t('Ticketing.tooltipNeveGiornaliera')
        },
        precipitazione: {
          title: this.$t('Ticketing.precipitazione'),
          text: this.$t('Ticketing.tooltipPrecipitazione')
        },
        grandineGiornaliera: {
          title: this.$t('Ticketing.grandineGiornaliera'),
          text: this.$t('Ticketing.tooltipGrandineGiornaliera')
        },
        ventoGiornaliero: {
          title: this.$t('Ticketing.ventoGiornaliero'),
          text: this.$t('Ticketing.tooltipVentoGiornaliero')
        },
        temperaturaMinimaGiornaliera: {
          title: this.$t('Ticketing.temperaturaMinimaGiornaliera'),
          text: this.$t('Ticketing.tooltipTemperaturaMinimaGiornaliera')
        },
        temperaturaMassimaGiornaliera: {
          title: this.$t('Ticketing.temperaturaMassimaGiornaliera'),
          text: this.$t('Ticketing.tooltipTemperaturaMassimaGiornaliera')
        }
      },
      selectedTooltipDataDocument: {},
      selectedTooltipDataMeteo: {},
      ticketingTranslationConfig: []
    };
  },
  computed: {
    ...mapState(['policy', 'ticketing', 'file_transfer', 'app_user', 'map']),
    rangeDateText() {
      const start_date = this.rangeDate[0] ? `${moment(this.rangeDate[0]).format('DD-MM-YYYY')},` : '';
      const end_date = this.rangeDate[1] ? `${moment(this.rangeDate[1]).format('DD-MM-YYYY')}` : '';

      return `${start_date} ${end_date}`;
    },
    calendarInfoText() {
      const text = this.$t('Ticketing.maxDateLimit');

      return this.isProvinciale ? text.replace('3', '1').replace('mesi', 'mese').replace('months', 'month') : text;
    },
    isRelazione() {
      return Object.keys(this.selectedDocumentTypes).length > 0 && this.$t(this.selectedDocumentTypes.document_type).toLowerCase().includes('relazione');
    },
    isComunale() {
      return Object.keys(this.selectedDocumentTypes).length > 0 && this.$t(this.selectedDocumentTypes.document_type).toLowerCase().includes('comunale');
    },
    isPuntuale() {
      return Object.keys(this.selectedDocumentTypes).length > 0 && this.$t(this.selectedDocumentTypes.document_type).toLowerCase().includes('puntuale');
    },
    isProvinciale() {
      return Object.keys(this.selectedDocumentTypes).length > 0 && this.$t(this.selectedDocumentTypes.document_type).toLowerCase().includes('provinciale');
    },
    isReport() {
      return Object.keys(this.selectedDocumentTypes).length > 0 && this.$t(this.selectedDocumentTypes.document_type).toLowerCase().includes('report');
    },
    isLocationItemsSelected() {
      return this.locationItems.length > 0;
    },
    checkRequestManual() {
      return (this.isReport && !this.selectedMeteoTypes.is_auto) || this.checkRequestType() === 'M';
    },
    confirmIsDisabled() {
      if (this.isLocationItemsSelected) {
        if (!this.selectedMeteoTypes.is_auto || this.checkRequestType() === 'M') {
          return this.isReport ? !this.evasion_limit : false;
        }

        return false;
      }

      return true;
    },
    placeName() {
      return this.isProvinciale ? this.map.addressProv : this.map.address;
    },
    disableFindAddress() {
      if (this.valid) {
        return this.selectedAddressType === 'Address' || (this.latitude === '' || this.longitude === '');
      }

      return !this.valid;
    }
  },
  watch: {
    $route() {},
    '$route.params': {
      handler(newValue) {
        this.service_id = newValue.id;
      },
      immediate: true
    }
  },
  async mounted() {
    await this.getTicketingConfiguration();
    // await this.getTicketingTranslation();

    this.map.landmark.setPosition(undefined);

    if (this.policy.enabledServicesPolicy.length == 0) {
      this.$router.push('/');
    }

    await this.checkTicketingRequestLimit();
    // eslint-disable-next-line no-undef
    this.autocomplete = new google.maps.places.Autocomplete(
      (this.$refs.autocomplete),
      { types: ['geocode'] }
    );

    this.autocomplete.addListener('place_changed', () => {
      const place = this.autocomplete.getPlace();
      this.map.address = place.formatted_address;
      this.latitude = place.geometry.location.lat();
      this.longitude = place.geometry.location.lng();
    });

    this.checkMapPaddingSize();
    window.addEventListener('resize', this.checkMapPaddingSize);
  },
  beforeDestroy() {
    if (this.pin !== null) {
      this.pin.setPosition(undefined);
      this.pin = null;
    }

    this.map.mapPadding = [0, 0, 0, 0];
    this.changeViewPadding();
    this.map.OLmap.un('click', this.enableManualGeocoder);
    window.removeEventListener('resize', this.checkMapPaddingSize);
  },
  methods: {
    ...mapActions(['listComuniProvinceAction', 'storeGetTicketingServiceConfiguration', 'storeGetTicketingServiceTranslation', 'createTicketingRequestAction', 'linkLocationToTicketingRequestAction', 'changeViewPadding', 'linkDocumentS3ToTicketingRequestAction', 'ticketingReverseGeocoding', 'listTicketingRequestLimitAction', 'resetDrawer', 'storeToastMessage']),
    async getTicketingConfiguration() {
      this.overlay = true;
      await this.storeGetTicketingServiceConfiguration(this.service_id);
      this.overlay = false;

      this.ticket_id = this.ticketing.ticketingServiceConfiguration.ticket_id;

      if (this.ticketing.ticketingServiceConfiguration.config?.length > 0) {
        const checkPermissions = this.policy.ticketingServicePermissions.filter(e => e.permission_type === 'requests').map(e => e.permission_name.split(',')[0]);
        this.documentTypesList = JSON.parse(JSON.stringify(this.ticketing.ticketingServiceConfiguration.config.filter(e => checkPermissions.includes(e.document_type))));
      }
    },
    async getTicketingTranslation() {
      if (this.ticketing.ticketingServiceConfiguration.ticket_id) {

        const languageUsage = this.app_user.app_user_data[0].language.toLowerCase();

        const data = {
          ticket_id: this.ticketing.ticketingServiceConfiguration.ticket_id,
          lang: [languageUsage]
        };

        this.overlay = true;
        await this.storeGetTicketingServiceTranslation(data);
        this.overlay = false;

        this.ticketingTranslationConfig = this.ticketing.ticketingTranslationServiceConfiguration;
      }
    },
    updateSelectedTooltipDataDocument() {
      if (Object.keys(this.selectedDocumentTypes).length > 0) {
        const selectDocument = this.selectedDocumentTypes.document_type.toLowerCase();

        for (const key in this.tooltipDataDocument) {
          if (selectDocument.includes(key)) {
            this.selectedTooltipDataDocument = this.tooltipDataDocument[key];

            return;
          }
        }
      }
    },
    updateSelectedTooltipDataMeteo() {
      if (Object.keys(this.selectedMeteoTypes).length > 0) {
        const selectMeteo = this.selectedMeteoTypes?.name?.toLowerCase().replace(/\s|\(|\)/g, '');

        for (const key in this.tooltipDataMeteo) {
          if (selectMeteo === key.toLowerCase()) {
            this.selectedTooltipDataMeteo = this.tooltipDataMeteo[key];

            return;
          }
        }
      }
    },
    formatTooltipText(text) {
      const paragraphs = text.split('- ').filter(paragraph => paragraph.trim() !== '');
      const formattedText = paragraphs.map(paragraph => `<br> - ${paragraph}`).join('');

      return formattedText;
    },
    checkForm() {
      let requiredParams = this.valid && this.rangeDate.length > 0;
      requiredParams = requiredParams && this.latitude !== '' && this.longitude !== '';
      requiredParams = requiredParams && Object.keys(this.selectedDocumentTypes).length !== 0 && Object.keys(this.selectedMeteoTypes).length !== 0;

      requiredParams = this.isComunale || this.isProvinciale
        ? requiredParams && this.selectedAddressFromSelect !== ''
        : requiredParams && !this.isManualCoordinates && this.placeName !== '';

      return !requiredParams;
    },
    closeDateRange() {
      this.rangeMenu = false;
      this.$refs.rangeMenu.save([]);
    },
    getCoordinatesFromAddress() {
      // eslint-disable-next-line no-undef
      const geocoder = new google.maps.Geocoder();
      geocoder
        .geocode({ address: this.selectedAddressFromSelect })
        .then((response) => {
          const location = response.results[0].geometry.location;
          this.latitude = location.lat();
          this.longitude = location.lng();

          if (this.isProvinciale) {
            this.map.addressProv = this.selectedAddressFromSelect;
          } else {
            this.map.address = this.selectedAddressFromSelect;
          }
        })
        .catch((e) => {
          if (e) {
            this.storeToastMessage({
              text: 'Address not found' + e,
              type: 'error'
            });
          }
        });
    },
    autocompleteInit() {
      // eslint-disable-next-line no-undef
      this.autocomplete = new google.maps.places.Autocomplete(
        (this.$refs.autocomplete),
        { types: ['geocode'] }
      );

      this.autocomplete.addListener('place_changed', () => {
        const googlePlace = this.autocomplete.getPlace();
        let place = '';

        googlePlace.address_components.forEach(item => {
          if (item.types.includes('country')) {
            this.country = item.short_name;
          }

          if (this.isProvinciale && item.types[0] === 'administrative_area_level_2') {
            place = `${item.long_name.replace('Città Metropolitana di ', '').replace('Provincia di ', '')} (${item.short_name})`;
          } else {
            if (item.types[0] === 'administrative_area_level_3') {
              place += item.short_name;
            }

            if (place !== '' && item.types[0] === 'administrative_area_level_2') {
              place += ` (${item.short_name})`;
            }
          }
        });

        if (place === '') {
          place = googlePlace.formatted_address;
        }

        this.map.ticketing_formatted_address = googlePlace.formatted_address;
        this.map.address = place || 'SNC';
        this.map.addressProv = place || 'SNC';
        this.latitude = googlePlace.geometry.location.lat();
        this.longitude = googlePlace.geometry.location.lng();
      });
    },
    resetAddress() {
      this.map.address = '';
      this.map.addressProv = '';
      this.latitude = '';
      this.longitude = '';
      this.isManualCoordinates = false;

      if (this.pin !== null) {
        this.pin.setPosition(undefined);
      }
    },
    dispatchManualGeocoderMarker() {
      this.ticketingReverseGeocoding([this.longitude, this.latitude]);
      const lonLat = fromLonLat([this.longitude, this.latitude]);
      this.pin.setPosition(lonLat);
      this.map.OLmap.getView().setCenter(lonLat);
      this.isManualCoordinates = false;
    },
    addLocation() {
      const startDate = this.rangeDate[0];
      const endDate = this.rangeDate[1];

      this.locationItems.push({
        latitude: this.latitude,
        longitude: this.longitude,
        meteo_type: `${this.selectedMeteoTypes.name}, ServiceCard.${this.ticket_id}.${this.selectedMeteoTypes.meteo_id}.meteoType`,
        ticketing_formatted_address: this.map.ticketing_formatted_address,
        address: this.placeName,
        country: this.country,
        start_date: startDate,
        end_date: endDate
      });

      this.checkMaxPointsLimitMsg();

      this.map.address = '';
      this.map.addressProv = '';
      this.evasion_limit = '';
      this.selectedAddressFromSelect = '';
    },
    updateMeteoType() {
      this.updateSelectedTooltipDataMeteo();

      this.locationItems.forEach(item => {
        item.meteo_type = `${this.selectedMeteoTypes.name}, ServiceCard.${this.ticket_id}.${this.selectedMeteoTypes.meteo_id}.meteoType`;
      });
    },
    splitMeteoOrDocumentType(item) {
      const documentOrMeteoSplitted = item.split(', ');
      const serviceCard = documentOrMeteoSplitted[1];

      return this.$t(serviceCard);
      // const [type] = item.split(', ');

      // return type;
    },
    deleteItem(item) {
      this.locationItems.splice(this.locationItems.indexOf(item), 1);
      this.checkMaxPointsLimitMsg();
    },
    checkMaxPointsLimitMsg() {
      const maxPointsLimit = this.isProvinciale ? 1 : 5;
      this.showMaxLimitMsg = !this.isRelazione && this.locationItems.length >= maxPointsLimit;
    },
    updateMeteoTypeList() {
      this.ticketing.ticketingServiceConfiguration.config.forEach(element => {
        if (this.selectedDocumentTypes.document_id === element.document_id) {
          this.selectedMeteoTypes = {};
          this.meteoTypesList = [];
          element.meteo_types?.forEach(item => {
            this.meteoTypesList.push(item);
          });
        }
      });
    },
    async disableMarker() {
      if (this.isProvinciale || this.isComunale) {
        this.overlay = true;
        await this.listComuniProvinceAction(this.isProvinciale);
        this.overlay = false;
      }

      this.updateMeteoTypeList();
      this.updateSelectedTooltipDataDocument();
      this.rangeDate = [];
      this.evasion_limit = '';
      this.locationItems = [];
      this.checkMaxPointsLimitMsg();
      this.selectedAddressFromSelect = '';
      this.latitude = '';
      this.longitude = '';
      this.map.address = '';
      this.map.addressProv = '';

      const checkTimeRange = (time) => {
        const [start, end] = time;
        const startDate = new Date(start).getTime();
        const endDate = new Date(end).getTime();
        const result = (endDate - startDate) / (1000 * 3600 * 24);

        return result > 90;
      };

      if (!this.isRelazione && this.rangeDate.length > 1) {
        if (checkTimeRange(this.rangeDate)) {
          this.rangeDate = [];
        }
      }

      if (this.isPuntuale || this.isRelazione) {
        this.overlayConfig();
        this.map.OLmap.on('click', this.enableManualGeocoder);
      } else {
        if (this.pin !== null) {
          this.pin.setPosition(undefined);
          this.pin = null;
        }

        this.latitude = '';
        this.longitude = '';
        this.map.address = '';
        this.map.addressProv = '';
        this.map.OLmap.un('click', this.enableManualGeocoder);
      }
    },
    checkRequestType() {
      const requestType = this.selectedMeteoTypes.is_auto ? 'A' : 'M';
      const date_limit = this.ticketing.ticketingServiceConfiguration.date_limit;
      const toBeManual = this.locationItems.some(e => {

        if (!this.isComunale && !this.isProvinciale) {
          const isOutsideItaly = !e.ticketing_formatted_address?.includes('Italia') && !e.ticketing_formatted_address?.includes('Italy') && !e.ticketing_formatted_address?.includes('Italien');
          // const isOutsideItaly = e.country !== 'IT';

          return e.start_date < date_limit || e.address === 'SNC' || isOutsideItaly;
        }

        return e.start_date < date_limit || e.address === 'SNC';
      });

      return toBeManual ? 'M' : requestType;
    },
    async confirmRequest() {
      const translatedDocumentType = `${this.selectedDocumentTypes.document_type}, ServiceCard.${this.ticket_id}.${this.selectedDocumentTypes.document_id}.documentType`;
      const node_name = this.policy.ticketingServicePermissions[0].node_name;
      const surname = this.policy.ticketingServicePermissions[0].surname;
      const email = this.policy.ticketingServicePermissions[0].email;
      const phone = this.policy.ticketingServicePermissions[0].phone;
      const crm_id = this.policy.ticketingServicePermissions[0].crm_id;
      const ragione_sociale = this.policy.ticketingServicePermissions[0].ragione_sociale;
      const _name = this.policy.ticketingServicePermissions[0]._name;

      this.locationItems.forEach(location => {
        this.location_id = uuidv4();
        location.app_user_ticket_id = this.ticketing.app_user_ticket_id,
        location.request_id = this.ticketing.request_id;
        location.location_id = this.location_id;
      });

      const single_notification = this.policy.ticketingServicePermissions.some(item => item.permission_name === 'Invio notifica al singolo utente');
      const group_notification = this.policy.ticketingServicePermissions.some(item => item.permission_name === 'Invio notifica di gruppo');
      const send_notification = single_notification || group_notification;

      const requestData = {
        app_user_ticket_id: this.ticketing.app_user_ticket_id,
        request_id: this.ticketing.request_id,
        request_type: this.checkRequestType(),
        document_type: translatedDocumentType,
        username: this.app_user.username,
        evasion_date: '',
        evasion_limit: this.checkRequestManual ? this.evasion_limit : '',
        request_date: moment().format('YYYY-MM-DD'),
        request_state: 'SENT',
        notes: this.notes || '',
        node_name: node_name,
        surname: surname,
        email: email,
        phone: phone,
        crm_id: crm_id,
        ragione_sociale: ragione_sociale,
        _name: _name,
        locations: [...this.locationItems],
        send_email: send_notification,
        group_notification: group_notification,
        can_be_moved: this.requestCanBeMoved
      };

      requestData.locations.forEach(element => {
        delete element.ticketing_formatted_address;
        delete element.country;
      });

      this.overlay = true;
      await this.createTicketingRequestAction(requestData);

      if (this.ticketing.createRequestState) {
        await this.linkLocationToTicketingRequestAction(this.locationItems);

        if (this.ticketing.documentS3ItemsAppUser.length > 0) {
          await this.linkDocumentS3ToTicketingRequestAction(this.ticketing.documentS3ItemsAppUser);
        }
      }

      this.overlay = false;
      this.ticketing.documentS3ItemsAppUser = [];
      this.$router.push({
        name: 'TicketingRequestList',
        params: { id: this.service_id }
      });
    },
    getCalendarMaxDate() {
      const todayDate = moment();
      const startDate = moment(this.rangeDate[0]);
      const monthsLimit = this.isProvinciale ? 1 : 3;
      const maxDate = moment(startDate).add(monthsLimit, 'months');
      let subtractedDate;
      let limitDate;

      if (!this.isRelazione && this.selectedMeteoTypes.is_auto) {
        const hour = this.isPuntuale ? 14 : 18;
        const referenceTime = moment().set('hour', hour).set('minute', 0).set('second', 0);

        const days = referenceTime && moment().isBefore(referenceTime) ? 3 : 2;
        subtractedDate = moment(todayDate).subtract(days, 'days');
        limitDate = moment.min([subtractedDate, maxDate]);
      } else {
        limitDate = moment.min([todayDate, maxDate]);
      }

      return moment(limitDate).format('YYYY-MM-DD');
    },
    canBeMoved(prevFreeSlots) {
      this.requestCanBeMoved = !this.isProvinciale && !this.isRelazione && !prevFreeSlots;
    },
    updateEvasionLimit(value) {
      this.evasion_limit = value;
    },
    async checkTicketingRequestLimit() {
      await this.listTicketingRequestLimitAction(this.app_user.crm_id);
      const findCrm = this.ticketing.ticketingRequestLimit.find(e => e.crm_id === this.app_user.crm_id);
      this.requestLimitReached = findCrm ? findCrm.limit_reached : false;
    },
    overlayConfig() {
      this.pin = new Overlay({
        positioning: 'bottom-center',
        element: document.getElementById('pin'),
        stopEvent: false
      });
      this.map.OLmap.addOverlay(this.pin);
    },
    enableManualGeocoder(e) {
      const lonLat = toLonLat(e.coordinate);
      this.latitude = lonLat[1];
      this.longitude = lonLat[0];
      this.pin.setPosition(e.coordinate);
      this.ticketingReverseGeocoding(lonLat);
    },
    checkMapPaddingSize() {
      const element = document.getElementById('rightBar');
      const width = element?.offsetWidth;
      this.map.mapPadding = [0, width - 40, 0, 0];
      this.changeViewPadding();
    },
    isLandscape() {
      return /Mobi|Android/i.test(navigator.userAgent) && window.innerHeight < window.innerWidth;
    },
    mobileBreakpoints() {
      return /Mobi|Android/i.test(navigator.userAgent) || this.isLandscape();
    }
  }
};
</script>

<style scoped>
#rightBar {
    height: 100%!important;
  }

.top-component-class {
  position: fixed;
  right: 0em;
  top: 0em;
  max-height: 100vh;
  width: 50%;
  z-index: 4;
  overflow: auto;
}
.ticketing-card-title {
  color: #0da344;
  background-color: #f4f3f3;
  margin-top: 1%;
  font-size: 22px;
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-size: 16px;
}
.search-location {
  width: 100%;
  border: 0;
  margin-top:1%;
  margin-bottom: 2%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.40);
}
.search-location:focus {
  outline: none;
}
.ticketing-card-subtitle{
  font-size: 18px;
}
input[type="text"] {
  font-size:16px;
}
#locations-table {
  white-space: nowrap !important;
}
@media (max-width: 600px), (max-height: 600px) and (orientation: landscape){
  .top-component-class {
    width: 100%;
  }
  .mobile-margin{
    margin-left: 40px !important;
  }
  .slider {
    height: calc(100% - 64px);
    overflow-y: auto;
    padding-bottom: 12px;
  }
}
</style>
