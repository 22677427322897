/* eslint-disable no-undef */
import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
// import XYZ from 'ol/source/XYZ';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import TileWMS from 'ol/source/TileWMS';
import GeoJSON from 'ol/format/GeoJSON';
import { fromLonLat, toLonLat } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import { Fill, RegularShape, Circle, Stroke, Style, Text } from 'ol/style';
import { bbox as bboxStrategy } from 'ol/loadingstrategy';
import Overlay from 'ol/Overlay';
import Select from 'ol/interaction/Select';
import { MouseWheelZoom, defaults, Draw } from 'ol/interaction';
import { always, platformModifierKeyOnly } from 'ol/events/condition.js';
import { pointerMove, singleClick } from 'ol/events/condition';
import KMLSource from 'ol/format/KML';
import Feature from 'ol/Feature';
import { Polygon, MultiPolygon, Point, LineString } from 'ol/geom';
import { getVectorLayer, getLegendValues, getLayerCoordinatesValues } from '@/api/services.js';
import TileGrid from 'ol/tilegrid/TileGrid';
// import { get as getProjection } from 'ol/proj';
import { getWidth, containsExtent, intersects } from 'ol/extent';
import * as Control from 'ol/control';
import { getArea, getLength } from 'ol/sphere';
import router from '@/router/index.js';
import { RASTER_BASE_URL, S3_BUCKETS, log } from '@/constants.js';
import { getFormattedDate, updatedDateWithTimeStep } from '@/utils.js';
import shp from 'shpjs';
import Vue from 'vue';
import { MAPBOX_TOKEN, MAPBOX_STYLE } from '@/constants';
import Layer from 'ol/layer/Layer';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';


export const state = {
  googleMap: null,
  marker: null,
  geocoder: null,
  OLmap: null,
  MBmap: null,
  animationInterval: null,
  mapLocale: 'Italian',
  mapTypeId: 'terrain',
  measureToolValue: '',
  locationName: '',
  latitude: '',
  longitude: '',
  address: '',
  addressProv: '',
  placeAddress: '',
  serviceSelectedId: '',
  lastUpdatedLayerDate: '',
  legend_vector: '',
  ticketing_formatted_address: '',
  selectedFeatureLayerName: '',
  selectedFeatureServiceID: '',
  selectedFeatureCheckParam: '',
  rasterMarkerLabel: '',
  rasterMarkerUdm: '',
  rasterMarkerValue: '',
  rasterMarkerDate: '',
  clickedPlace: '',
  mouseCoord: '',
  sliderOpacityValue: 60,
  selectedFeatureDigits: 0,
  zoom: 5,
  meteogramActive: false,
  measureToolActive: false,
  rasterVisible: true,
  disableAnimationPlay: false,
  geocoderResponse: false,
  isCenterMarker: false,
  isVectorPopup: false,
  sendCenterEvent: false,
  sendClickEvent: false,
  isPopupMarker: true,
  reloadLegend: false,
  legendMenu: false,
  layerToSelectVector: {},
  layerToSelectRaster: {},
  refreshIntervalID: {},
  colorFill: {},
  colorStroke: {},
  strokeWidth: {},
  zIndexValue: {},
  legend_vectorRules: {},
  selectedRasterLayer: {},
  selectedFeatureObj: {},
  renderOpacityMenu: {},
  runtime: {},
  vectorParamRange: {},
  specificDefaultService: { Archive: false, RealTime: false, Forecast: false },
  isAnimating: [],
  coordsLonLat: [],
  distanceFromPoint: [],
  userLocation: [],
  vectorTableHeaders: [],
  vectorBBOX: [],
  mapPadding: [0, 0, 0, 0],
  mapCenter: [1594684.1859, 4985987.3737],
  ignoreProperties: ['geometry', 'ignore_id', 'type_property', 'virtual_station', 'geom_property', 'no_standard_property'],
  mapStyle: 'classic',
  landmark: new Overlay({
    positioning: 'bottom-center',
    stopEvent: true
  }),
  popup: new Overlay({
    positioning: 'bottom-center',
    stopEvent: true
  }),
  select: new Select({
    layers: function (layer) {
      return layer.values_.layer_type === 'VECTOR';
    },
    condition: singleClick,
    style: false
  })
  // OLLayers: [
  //   new TileLayer({
  //     source: new XYZ({
  //       url: 'https://mt0.google.com/vt/lyrs=m&hl=it&x={x}&y={y}&z={z}'
  //     })
  //   })
  // ]
};

export const mutations = {};

export const actions = {
  // ------------------------ MAP ---------------------------
  initiateGoogleMapAction({ state, dispatch }, mapId) {
    try {
      const mapOptions = {
        zoom: 5,
        center: { lat: 45.29509029836977, lng: 11.819342135290803 },
        mapTypeId: 'terrain',
        mapTypeControl: false,
        streetViewControl: false,
        zoomControl: false,
        options: {
          gestureHandling: 'greedy'
        }
      };
      state.googleMap = new google.maps.Map(
        document.getElementById(mapId),
        mapOptions
      );

      if (mapId === 'ticketingMap') {
        state.geocoder = new google.maps.Geocoder();
        dispatch('setGeocoderMarker');
        dispatch('manualGeocoderMarker');
      }
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'initiateGoogleMapAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  initiateOLMapAction({ rootState, state, dispatch, commit }) {
    try {
      mapboxgl.accessToken = MAPBOX_TOKEN;
      state.MBmap = new mapboxgl.Map({
        projection: 'mercator',
        style: `mapbox://styles/${MAPBOX_STYLE[state.mapStyle]}`,
        attributionControl: false,
        boxZoom: false,
        center: toLonLat(state.mapCenter),
        container: 'map',
        language: state.mapLocale,
        locale: state.mapLocale,
        doubleClickZoom: false,
        dragPan: false,
        dragRotate: false,
        // interactive: false,
        keyboard: false,
        pitchWithRotate: false,
        scrollZoom: false,
        touchZoomRotate: false,
        logoPosition: 'bottom-right'
      });

      const MBLayers = new Layer({
        render: function (frameState) {
          const canvas = state.MBmap.getCanvas();
          const viewState = frameState.viewState;

          const visible = MBLayers.getVisible();
          canvas.style.display = visible ? 'block' : 'none';

          const opacity = MBLayers.getOpacity();
          canvas.style.opacity = opacity;

          // adjust view parameters in mapbox
          const rotation = viewState.rotation;
          state.MBmap.jumpTo({
            center: toLonLat(viewState.center),
            zoom: viewState.zoom - 1,
            bearing: (-rotation * 180) / Math.PI,
            animate: false
          });

          // cancel the scheduled update & trigger synchronous redraw
          // see https://github.com/mapbox/mapbox-gl-js/issues/7893#issue-408992184
          if (state.MBmap._frame) {
            state.MBmap._frame.cancel();
            state.MBmap._frame = null;
          }

          state.MBmap._render();

          return canvas;
        }
      });

      state.OLmap = new Map({
        layers: [MBLayers],
        interactions: defaults({
          mouseWheelZoom: false,
          doubleClickZoom: false,
          altShiftDragRotate: false,
          pinchRotate: false
        }).extend([
          new MouseWheelZoom({
            constrainResolution: true,
            condition: router.currentRoute.path.includes('reports')
              ? platformModifierKeyOnly
              : always
          })
        ]),
        target: 'map',
        view: new View({
          // projection: 'EPSG:4326',
          padding: state.mapPadding,
          center: state.mapCenter,
          zoom: state.zoom
        })
      });

      if (!router.currentRoute.path.includes('reports')) {
        dispatch('setMarkerOnClick');
        dispatch('reloadVectorLayerAfterZoom');

        const selectPointerMove = new Select({
          layers: function (layer) {
            return layer.values_.layer_type === 'VECTOR';
          },
          condition: pointerMove,
          style: false
        });

        state.OLmap.addInteraction(selectPointerMove);
        state.OLmap.addInteraction(state.select);

        //   state.OLmap.removeOverlay(state.landmark);
        //   state.landmark.setPosition(undefined);

        // selectPointerMove.on('pointermove', function () {
        // });

        state.OLmap.on('moveend', function () {
          state.vectorBBOX = state.OLmap.getView().calculateExtent(state.OLmap.getSize());

          const centerCoordinates = toLonLat(state.OLmap.getView().getCenter());
          const timeStamp = Date.now();
          // if (state.OLmap.getLayers().getArray().slice(1).length === 0) {
          //   Vue.$gtag.event('Centroide mappa', {
          //     event_category: `${router.currentRoute.params.id == undefined ? 'Home' : router.currentRoute.params.id} - Lat ${centerCoordinates[1].toFixed(4)} - Lon ${centerCoordinates[0].toFixed(4)}`,
          //     event_label: `${timeStamp} - ${rootState.app_user.username} - ${rootState.app_user.crm_id} - ${getFormattedDate(new Date(), true, true)}`,
          //     value: 'Null'
          //   });
          // }
          state.OLmap.getLayers().getArray().slice(1).forEach(layer => {
            if (layer.values_.layer_type === 'VECTOR') {
              Vue.$gtag.event('Centroide mappa', {
                event_category: `${router.currentRoute.params.id == undefined ? 'Home' : router.currentRoute.params.id} - Lat ${centerCoordinates[1].toFixed(4)} - Lon ${centerCoordinates[0].toFixed(4)}`,
                event_label: `${timeStamp} - ${rootState.app_user.username} - ${rootState.app_user.crm_id} - ${getFormattedDate(new Date(), true, true)}`,
                value: `${layer.values_.service_name}: ${layer.values_.layer_name.split(':')[1]} - ${getFormattedDate(new Date(layer.values_.time.split('/')[0]), true)} - ${getFormattedDate(new Date(layer.values_.time.split('/')[1]), true)}`
              });
            }

            if (layer.values_.layer_type === 'RASTER') {
              Vue.$gtag.event('Centroide mappa', {
                event_category: `${router.currentRoute.params.id == undefined ? 'Home' : router.currentRoute.params.id} - Lat ${centerCoordinates[1].toFixed(4)} - Lon ${centerCoordinates[0].toFixed(4)}`,
                event_label: `${timeStamp} - ${rootState.app_user.username} - ${rootState.app_user.crm_id} - ${getFormattedDate(new Date(), true, true)}`,
                value: `${layer.values_.service_name}: ${layer.values_.layer_name.split(':')[1]} - ${getFormattedDate(new Date(layer.values_.source.params_.TIME), true)}`
              });
            }
          });
        });

        state.select.on('select', function (e) {
          if (e.selected.length > 0) {
            state.isVectorPopup = true;
            state.isPopupMarker = false;
            const featureID = e.selected[0].ol_uid;
            state.selectedFeatureLayerName = e.target.featureLayerAssociation_[featureID].values_.layer_name;
            state.selectedFeatureObj = e.selected[0].values_;
            state.selectedFeatureCheckParam = e.selected[0].values_.radarmeteo_id || e.selected[0].values_.area || e.selected[0].values_.Area;
            const popupPosition = e.selected[0].getGeometry().getType() === 'Point'
              ? e.selected[0].getGeometry().flatCoordinates
              : e.mapBrowserEvent.coordinate;
            state.popup.setPosition(popupPosition);
            state.popup.setElement(document.getElementById('popup'));
            state.OLmap.addOverlay(state.popup);
            state.popup.panIntoView();

            state.selectedFeatureServiceID = '';

            state.OLmap.getLayers().getArray().slice(1).forEach(layer => {
              if (layer.values_.layer_name.includes(state.selectedFeatureLayerName.split(':')[1])) {
                state.selectedFeatureServiceID = layer.values_.service_id;
              }
            });

            const data = {
              service_id: state.selectedFeatureServiceID,
              feature_id: state.selectedFeatureObj.ignore_id
            };

            commit('GET_FILTERED_CHART_ITEMS', data);
          } else {
            dispatch('closePopupOnMap');
          }
        });
      }

      state.OLmap.on('pointermove', function (e) {
        state.mouseCoord = toLonLat(e.coordinate);
      });

    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'initiateOLMapAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }

    state.OLmap.getControls().forEach(control => {
      if (control instanceof Control.Zoom) {
        state.OLmap.removeControl(control);
      }
    });
  },
  async removeLayer({ rootState, state, dispatch }, data) {
    for await (const layer of state.OLmap.getLayers().getArray()) {
      if (data === layer.values_.layer_name) {
        await state.OLmap.removeLayer(layer);
      }

      if (layer.values_.layer_type === 'VECTOR') {
        dispatch('closePopupOnMap');
      }

      if (layer.values_.layer_type === 'VECTOR') {
        rootState.geoserver_data.vectorLegend_selectedLabel[layer.values_.layer_name.split(':')[1]] = [];
      }
    }
  },
  async removeLayerByServiceID({ rootState, state, dispatch }, data) {
    for await (const layer of state.OLmap.getLayers().getArray()) {
      if (data) {
        if (layer.values_.service_id === data) {
          await state.OLmap.removeLayer(layer);
        }

        if (layer.values_.layer_type === 'VECTOR') {
          dispatch('closePopupOnMap');
        }

        if (layer.values_.layer_type === 'VECTOR') {
          rootState.geoserver_data.vectorLegend_selectedLabel[layer.values_.layer_name.split(':')[1]] = [];
        }
      }
    }
  },
  async removeEveryRasterLayer({ state, dispatch }) {
    state.OLmap.getLayers().getArray().forEach(layer => {
      if (layer.values_.layer_type === 'RASTER') {
        state.OLmap.removeLayer(layer);
      }
    });

    if (state.coordsLonLat.length === 2) {
      dispatch('setRasterMarkerValue');
    }
  },
  async removeEveryLayer({ state }) {
    state.OLmap.getLayers().getArray().slice(1).forEach(layer => {
      if (layer.values_.layer_type !== 'GLOBAL LAYER') {
        state.OLmap.removeLayer(layer);
      }
    });
  },
  checkOpacityServiceID({ state }, id) {
    state.OLmap.getLayers().getArray().slice(1).forEach(layer => {
      if (layer.values_.service_id === id && layer.values_.layer_type !== 'METEOGRAM') {
        return state.sliderOpacityValue = layer.getOpacity() * 100;
      }
    });
  },
  setGeocoderMarker({ state }) {
    google.maps.event.addListener(state.googleMap, 'click', (event) => {
      setTimeout(function () {
        state.latitude = event.latLng.lat();
        state.longitude = event.latLng.lng();
        const latlng = {
          lat: state.latitude,
          lng: state.longitude
        };
        state.geocoder
          .geocode({ location: latlng })
          .then((response) => {
            if (response.results[0]) {
              let place = '';
              let prov = '';
              response.results[0].address_components.forEach(item => {
                if (item.types[0] === 'administrative_area_level_3') {
                  place += item.short_name;
                }

                if (item.types[0] === 'administrative_area_level_2') {
                  if (place !== '') {
                    place += ` (${item.short_name})`;
                  }

                  prov = `${item.long_name.replace('Città Metropolitana di ', '').replace('Provincia di ', '')} (${item.short_name})`;
                }
              });

              if (place === '') {
                if (response.results[0].address_components[0].types[0] === 'plus_code') {
                  const toRemove = response.results[0].address_components[0].long_name;
                  place = response.results[0].formatted_address.replace(toRemove, '');
                } else {
                  place = response.results[0].formatted_address;
                }
              }

              state.address = place;
              state.addressProv = prov;
            } else {
              window.alert('No results found');
            }
          })
          .catch((e) => window.alert('Geocoder failed due to: ' + e));

        if (!state.marker) {
          state.marker = new google.maps.Marker({
            map: state.googleMap
          });
        }

        state.marker.setPosition(event.latLng);
      }, 500);
    });
  },
  manualGeocoderMarker({ state }) {
    document.getElementById('submit').addEventListener('click', () => {
      setTimeout(function () {
        if (state.geocoder != undefined) {
          const latitude = document.getElementById('lat').value;
          const longitude = document.getElementById('lng').value;
          const latlng = {
            lat: parseFloat(latitude),
            lng: parseFloat(longitude)
          };
          state.geocoder
            .geocode({ location: latlng })
            .then((response) => {
              if (response.results[0]) {
                let place = '';
                let prov = '';
                response.results[0].address_components.forEach(item => {
                  if (item.types[0] === 'administrative_area_level_3') {
                    place += item.short_name;
                  }

                  if (item.types[0] === 'administrative_area_level_2') {
                    if (place !== '') {
                      place += ` (${item.short_name})`;
                    }

                    prov = `${item.long_name.replace('Città Metropolitana di ', '').replace('Provincia di ', '')} (${item.short_name})`;
                  }
                });

                if (place === '') {
                  if (response.results[0].address_components[0].types[0] === 'plus_code') {
                    const toRemove = response.results[0].address_components[0].long_name;
                    place = response.results[0].formatted_address.replace(toRemove, '');
                  } else {
                    place = response.results[0].formatted_address;
                  }
                }

                state.address = place;
                state.addressProv = prov;
              } else {
                window.alert('No results found');
              }
            })
            .catch((e) => window.alert('Geocoder failed due to: ' + e));

          if (!state.marker) {
            state.marker = new google.maps.Marker({
              map: state.googleMap
            });
          }

          state.marker.setPosition(latlng);
        }
      }, 500);
    });
  },
  changeViewPadding({ state }) {
    const view = new View({
      padding: state.mapPadding,
      center: state.mapCenter,
      zoom: state.zoom
    });
    state.OLmap.setView(view);
  },
  newMapCenter({ state, dispatch }, newCoord) {
    if (router.currentRoute.name !== 'TicketingRequest') {
      state.distanceFromPoint = newCoord;
      state.isCenterMarker = false;
      state.isPopupMarker = true;
      state.select.getFeatures().clear();
      dispatch('reverseGeocoding', newCoord);
      dispatch('closePopupOnMap');
      state.landmark.setPosition(newCoord);
      state.landmark.setElement(document.getElementById('landmark'));
      state.OLmap.addOverlay(state.landmark);
    }

    dispatch('changeViewPadding');
    state.OLmap.getView().setCenter(newCoord);

    const actualZoom = state.OLmap.getView().getZoom();
    state.OLmap.getView().setZoom((actualZoom <= 13) ? 13 : actualZoom);
  },
  zoomIn({ state }) {
    let actualZoom = state.OLmap.getView().getZoom();

    if (actualZoom < 22) {
      state.OLmap.getView().setZoom(actualZoom += 0.250000);
    }
  },
  zoomOut({ state }) {
    let actualZoom = state.OLmap.getView().getZoom();

    if (actualZoom > 1) {
      state.OLmap.getView().setZoom(actualZoom -= 0.250000);
    }
  },
  setZoomUser({ state }, data) {
    state.zoom = data;
    state.OLmap.getView().setZoom(state.zoom);
  },
  async getLocation({ state }) {
    if (!navigator.geolocation || !navigator.permissions) {
      /* eslint-disable-next-line no-console */
      console.info('Geolocation or Permissions API is not supported by this browser.');

      return;
    }


    try {
      const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });

      if (permissionStatus.state === 'granted') {
        navigator.geolocation.getCurrentPosition((position) => {
          state.userLocation = fromLonLat([position.coords.longitude, position.coords.latitude], 'EPSG:3857');
        });
      } else if (permissionStatus.state === 'prompt') {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            state.userLocation = fromLonLat([position.coords.longitude, position.coords.latitude], 'EPSG:3857');
          },
          (error) => {
            /* eslint-disable-next-line no-console */
            console.error('Error getting location: ', error);
          }
        );
      } else if (permissionStatus.state === 'denied') {
        alert('Access to location has been denied. Please enable location access in your browser settings.');
      }

      permissionStatus.onchange = () => {
        /* eslint-disable-next-line no-console */
        console.info('Permission state changed to:', permissionStatus.state);
      };
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.error('Error checking or requesting geolocation permission:', error);
    }
  },
  closePopupOnMap({ state }) {
    state.popup.setPosition(undefined);
    state.isVectorPopup = false;
    state.selectedFeatureServiceID = '';
    state.selectedFeatureCheckParam = '';
  },
  setMarkerOnClick({ state, dispatch }) {
    state.OLmap.on('dblclick', async function (evt) {
      if (!state.measureToolActive && router.currentRoute.name !== 'TicketingRequest') {
        state.sendCenterEvent = false;
        state.sendClickEvent = true;
        state.distanceFromPoint = evt.coordinate;
        state.rasterMarkerLabel = '';
        state.rasterMarkerUdm = '';
        state.rasterMarkerValue = '';
        state.isCenterMarker = false;
        state.isPopupMarker = true;
        state.select.getFeatures().clear();

        dispatch('reverseGeocoding', evt.coordinate);

        dispatch('closePopupOnMap');
        state.landmark.setPosition(evt.coordinate);
        state.landmark.setElement(document.getElementById('landmark'));
        state.OLmap.addOverlay(state.landmark);
        state.landmark.panIntoView();

        dispatch('setRasterMarkerValue');
      }
    });
  },
  async setRasterMarkerValue({ state, rootState }) {
    const activeRaster = state.OLmap.getLayers().getArray().slice(1).some(layer => layer.values_.layer_type === 'RASTER');

    if (activeRaster) {
      const raster = state.OLmap.getLayers().getArray().slice(1).filter(layer => layer.values_.layer_type === 'RASTER');
      const [workspace, layer] = raster[0].values_.layer_name.split(':');
      const date = new Date(state.rasterMarkerDate);
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const data = {
        time: date.toISOString(),
        username: rootState.app_user.username,
        workspace: workspace,
        layers: layer,
        latitude: state.coordsLonLat[1],
        longitude: state.coordsLonLat[0],
        output: 'json',
        timezone: timeZone,
        service_name: raster[0].values_.service_name,
        service_id: raster[0].values_.service_id,
        group: router.currentRoute.params.id,
        runtime: state.runtime[raster[0].values_.service_id]
      };

      const [isRuntime, timeStep, timeStepType] = rootState.table_chart.isRuntimeDomain[data.service_id] || [];
      const isV2 = ['HFS_ITA_4KM_1H', 'HFS_EUR_12KM_1H', 'HFS_WOR_25KM_1H'].includes(data.workspace) && isRuntime;

      if (isV2) {
        const rangeData = updatedDateWithTimeStep(timeStep, timeStepType, 'add_step', data.time);
        data.time += `/${new Date(rangeData).toISOString()}`;
      }

      try {
        const resp = await getLayerCoordinatesValues(data, isV2);
        state.rasterMarkerLabel = `${raster[0].values_.layer_name}_serviceID:${raster[0].values_.service_id}.label`;
        state.rasterMarkerUdm = `${raster[0].values_.layer_name}_serviceID:${raster[0].values_.service_id}.udm`;
        state.rasterMarkerValue = `: ${resp.data.data[raster[0].values_.layer_name][0].toFixed(1)}`;
      } catch {
        state.rasterMarkerLabel = '';
        state.rasterMarkerUdm = '';
        state.rasterMarkerValue = '';
      }
    } else {
      state.rasterMarkerLabel = '';
      state.rasterMarkerUdm = '';
      state.rasterMarkerValue = '';
    }
  },
  reverseGeocoding({ rootState, state }, coord) {
    state.coordsLonLat = toLonLat(coord);
    const latlng = {
      lat: state.coordsLonLat[1],
      lng: state.coordsLonLat[0]
    };

    const reverseGeocoding = new google.maps.Geocoder();
    reverseGeocoding
      .geocode({ location: latlng })
      .then((response) => {
        state.geocoderResponse = true;

        if (response.results[0].address_components[0].types[0] === 'plus_code') {
          const toRemove = response.results[0].address_components[0].long_name;
          state.placeAddress = response.results[0].formatted_address.replace(toRemove, '');
        } else {
          state.placeAddress = response.results[0].formatted_address;
        }

        state.clickedPlace = '';
        response.results[0].address_components.forEach(item => {
          if (item.types[0] === 'administrative_area_level_3') {
            state.clickedPlace += item.short_name;
          }

          if (state.clickedPlace !== '' && item.types[0] === 'administrative_area_level_2') {
            state.clickedPlace += ` (${item.short_name})`;
          }
        });

        if (state.clickedPlace === '') {
          if (response.results[0].address_components[0].types[0] === 'plus_code') {
            const toRemove = response.results[0].address_components[0].long_name;
            state.clickedPlace = response.results[0].formatted_address.replace(toRemove, '');
          } else {
            state.clickedPlace = response.results[0].formatted_address;
          }
        }

        if (state.sendCenterEvent) {
          Vue.$gtag.event('Geolocalizzazione', {
            event_category: `${router.currentRoute.params.id == undefined ? 'Home' : router.currentRoute.params.id}`,
            event_label: `${Date.now()} - ${rootState.app_user.username} - ${rootState.app_user.crm_id} - ${getFormattedDate(new Date(), true, true)}`,
            value: `${state.clickedPlace} - Lat ${state.coordsLonLat[1]} - Lon ${state.coordsLonLat[0]}`
          });
        }

        if (state.sendClickEvent) {
          Vue.$gtag.event('Località selezionata su mappa', {
            event_category: `${router.currentRoute.params.id == undefined ? 'Home' : router.currentRoute.params.id}`,
            event_label: `${Date.now()} - ${rootState.app_user.username} - ${rootState.app_user.crm_id} - ${getFormattedDate(new Date(), true, true)}`,
            value: `${state.clickedPlace} - Lat ${state.coordsLonLat[1]} - Lon ${state.coordsLonLat[0]}`
          });
        }
      })
      .catch((e) => {
        if (e) {
          state.geocoderResponse = false;
          state.placeAddress = 'Address not found';
        }
      });
    state.coordsLonLat[1] = state.coordsLonLat[1].toFixed(4);
    state.coordsLonLat[0] = state.coordsLonLat[0].toFixed(4);
  },
  ticketingReverseGeocoding({ state }, coord) {
    const [longitude, latitude] = coord;
    const latlng = {
      lat: parseFloat(latitude),
      lng: parseFloat(longitude)
    };

    const reverseGeocoding = new google.maps.Geocoder();
    reverseGeocoding
      .geocode({ location: latlng })
      .then((response) => {
        if (response.results[0]) {
          let place = '';
          let prov = '';
          response.results[0].address_components.forEach(item => {
            if (item.types[0] === 'administrative_area_level_3') {
              place += item.short_name;
            }

            if (item.types[0] === 'administrative_area_level_2') {
              if (place !== '') {
                place += ` (${item.short_name})`;
              }

              prov = `${item.long_name.replace('Città Metropolitana di ', '').replace('Provincia di ', '')} (${item.short_name})`;
            }
          });

          if (place === '') {
            if (response.results[0].address_components[0].types[0] === 'plus_code') {
              const toRemove = response.results[0].address_components[0].long_name;
              place = response.results[0].formatted_address.replace(toRemove, '');
            } else {
              place = response.results[0].formatted_address;
            }
          }

          state.ticketing_formatted_address = response.results[0].formatted_address;
          state.address = place || 'SNC';
          state.addressProv = prov || 'SNC';
        } else {
          window.alert('No results found');
        }
      })
      .catch((e) => window.alert('Geocoder failed due to: ' + e));
  },
  setPositionUser({ state }, data) {
    const lat = data[0];
    const long = data[1];
    state.mapCenter = fromLonLat([long, lat], 'EPSG:3857');
  },
  async toggleMeasureTool({ state }, toolType) {
    state.measureToolValue = '0.00 m';

    const vectorLayer = new VectorLayer({
      source: new VectorSource(),
      style: [new Style({
        stroke: new Stroke({
          color: '#000',
          width: 3
        }),
        fill: new Fill({ color: [255, 255, 255, 0.5] })
      })]
    });

    const areaInteraction = new Draw({
      type: 'Polygon',
      source: vectorLayer.getSource()
    });
    const distanceInteraction = new Draw({
      type: 'LineString',
      source: vectorLayer.getSource()
    });

    for await (const layer of state.OLmap.getLayers().getArray()) {
      if (layer.values_.layer_name === 'MEASURING TOOL') {
        await state.OLmap.removeLayer(layer);
      }
    }

    state.OLmap.getInteractions().forEach(interaction => {
      if (interaction instanceof Draw) {
        state.OLmap.removeInteraction(interaction);
      }

      if (interaction instanceof Select) {
        interaction.setActive(toolType === '');
      }
    });

    if (toolType !== '') {
      state.measureToolActive = true;

      if (toolType === 'area') {
        state.OLmap.addInteraction(areaInteraction);
        areaInteraction.on('drawstart', function (event) {
          vectorLayer.getSource().clear();

          event.feature.on('change', function (event) {
            const measureArea = getArea(event.target.getGeometry());
            state.measureToolValue = measureArea > 9999.99 ? (Math.round((measureArea / 1000000) * 100) / 100).toFixed(2) + ' km' : (Math.round(measureArea * 100) / 100).toFixed(2) + ' m';
          });
        });
      }

      if (toolType === 'distance') {
        state.OLmap.addInteraction(distanceInteraction);
        distanceInteraction.on('drawstart', function (event) {
          vectorLayer.getSource().clear();

          event.feature.on('change', function (event) {
            const measureLenght = getLength(event.target.getGeometry());
            state.measureToolValue = measureLenght > 999.99 ? (Math.round((measureLenght / 1000) * 100) / 100).toFixed(2) + ' km' : (Math.round(measureLenght * 100) / 100).toFixed(2) + ' m';
          });
        });
      }

      vectorLayer.set('layer_type', toolType);
      vectorLayer.set('layer_name', 'MEASURING TOOL');
      vectorLayer.setZIndex(5);
      state.OLmap.addLayer(vectorLayer);
    } else {
      state.measureToolActive = false;
    }
  },
  // ------------------------ RASTER ---------------------------
  async loadRasterLayer({ state, dispatch }, data) {
    const projExtent = state.OLmap.getView().getProjection('EPSG:3857').getExtent();
    const startResolution = getWidth(projExtent);
    let resolutions = Array.apply(null, new Array(22));
    resolutions = resolutions.map((item, index) => item = startResolution / Math.pow(2, index));
    const tileGrid = new TileGrid({
      minZoom: 6,
      extent: projExtent,
      resolutions: resolutions,
      tileSize: [512, 512]
    });
    const rasterLayer = new TileLayer({
      extent: data.EXTENT, // [minx, miny, maxx, maxy]
      source: new TileWMS({
        url: data.url,
        params: data.params,
        serverType: 'geoserver',
        tileGrid: tileGrid,
        transition: 0,
        crossOrigin: 'anonymous'
      })
    });

    rasterLayer.set('layer_type', data.TYPE);
    rasterLayer.set('layer_name', data.params.LAYERS);
    rasterLayer.set('layer_style', data.params.STYLES);
    rasterLayer.set('service_id', data.LAYER_SERVICE_ID);
    rasterLayer.set('service_name', data.params.SERVICE_NAME);

    dispatch('checkOpacityServiceID', data.LAYER_SERVICE_ID);

    const opacityValue = state.sliderOpacityValue / 100;
    rasterLayer.setOpacity(opacityValue);
    rasterLayer.setZIndex(state.rasterVisible ? 0 : -1);
    state.OLmap.addLayer(rasterLayer);

    // const rasterSource = rasterLayer.getSource();
    // rasterSource.on('tileloadend', async function() {
    //   for await (const selectedLayer of state.OLmap.getLayers().getArray().slice(1)) {
    //     if (selectedLayer.values_.layer_name === data.params.LAYERS && selectedLayer.values_.layer_type === "TO REMOVE") {
    //       setTimeout(function () {
    //         state.OLmap.removeLayer(selectedLayer);
    //         rasterLayer.setZIndex(0);
    //       }, 1000);
    //     }
    //   }
    // });

    const rasterSource = rasterLayer.getSource();
    rasterSource.on('change', async function () {
      const rasterState = await rasterSource.getState();

      if (rasterState === 'ready') {
        for await (const selectedLayer of state.OLmap.getLayers().getArray().slice(1)) {
          if (selectedLayer.values_.layer_name === data.params.LAYERS && selectedLayer.values_.layer_type === 'TO REMOVE') {
            setTimeout(function () {
              state.OLmap.removeLayer(selectedLayer);
              rasterLayer.setZIndex(0);
            }, 2000);
          }
        }
      }
    });
    const lastIndex = state.OLmap.getLayers().getArray().length - 1;
    state.OLmap.getLayers().getArray()[lastIndex].getSource().changed();

    if (state.coordsLonLat.length === 2) {
      dispatch('setRasterMarkerValue');
    }
  },
  setRasterLayerAnimationTime({ state, dispatch }, data) {
    const localISOTime = new Date(data.updatedDate).toISOString();
    state.rasterVisible = false;
    let rasterParams = {};
    state.OLmap.getLayers().getArray().forEach(layer => {
      if (layer.values_.service_id === data.service_id && layer.values_.layer_type === data.layerType) {
        rasterParams = {
          url: `${RASTER_BASE_URL}/fe_ogc/geoserver/wms`,
          params: {
            ENV: layer.values_.source.params_.ENV,
            GROUP: router.currentRoute.params.id,
            LAYERS: layer.values_.layer_name,
            STYLES: layer.values_.layer_style,
            TIME: localISOTime,
            USERNAME: layer.values_.source.params_.USERNAME,
            SERVICE_NAME: layer.values_.service_name,
            FORMAT: 'image/vnd.jpeg-png8'
          },
          TYPE: 'RASTER',
          LAYER_SERVICE_ID: layer.values_.service_id,
          EXTENT: data.extent
        };
        layer.set('layer_type', 'TO REMOVE');
      }
    });
    dispatch('loadRasterLayer', rasterParams);
    state.rasterVisible = true;
  },
  async resetAnimationTime({ state }) {
    window.clearInterval(state.animationInterval);
    state.animationInterval = null;
    state.disableAnimationPlay = false;
    state.isAnimating = [];
  },
  // ------------------------ VECTOR ---------------------------
  async loadVectorLayer({ state, dispatch }, data) {
    const createFeatureFromGeometry = (geometry, properties) => {
      if (geometry.type === 'Point') {
        return new Feature({
          geometry: new Point(geometry.coordinates),
          ...properties
        });
      }

      if (geometry.type === 'LineString') {
        return new Feature({
          geometry: new LineString(geometry.coordinates),
          ...properties
        });
      }

      if (geometry.type === 'Polygon') {
        return new Feature({
          geometry: new Polygon(geometry.coordinates),
          ...properties
        });
      }

      if (geometry.type === 'MultiPolygon') {
        return new Feature({
          geometry: new MultiPolygon(geometry.coordinates),
          ...properties
        });
      }
    };

    const vectorSource = new VectorSource({
      format: new GeoJSON(),
      strategy: bboxStrategy,
      loader: async function () {
        state.vectorBBOX = state.OLmap.getView().calculateExtent(state.OLmap.getSize());

        try {
          const contain = containsExtent(data.EXTENT, state.vectorBBOX);
          const intersect = intersects(data.EXTENT, state.vectorBBOX);

          if (contain || intersect) {
            const resp = await getVectorLayer(data.params.LAYERS, state.vectorBBOX, data.params.TIME, data.params.USERNAME, data.params.SERVICE_NAME, router.currentRoute.params.id, data.params.PARAM_RANGE);
            const features = await vectorSource.getFormat().readFeatures(resp.data);

            const checkSelectedFeatureExists = features.every(feature => feature.values_.radarmeteo_id !== undefined || feature.values_.area !== undefined || feature.values_.Area !== undefined);

            const checkSelectedFeatureParam = features.find(feature => feature.values_.radarmeteo_id === state.selectedFeatureCheckParam || feature.values_.area === state.selectedFeatureCheckParam || feature.values_.Area === state.selectedFeatureCheckParam);

            if (state.selectedFeatureServiceID === data.LAYER_SERVICE_ID) {
              if (checkSelectedFeatureExists && checkSelectedFeatureParam) {
                state.selectedFeatureObj = checkSelectedFeatureParam.values_;
                state.selectedFeatureLayerName = data.params.LAYERS;
                state.popup.setPosition(checkSelectedFeatureParam.getGeometry().flatCoordinates);
              } else {
                dispatch('closePopupOnMap');
              }
            }

            vectorSource.clear();

            let isGeomProperty = false;

            const addFeatureIfExists = (property, feature) => {
              if (feature.values_[property]) {
                // eslint-disable-next-line no-unused-vars
                const { geometry, ...values } = feature.values_;
                const newFeature = createFeatureFromGeometry(feature.values_[property], { ...values, geom_property: property });
                vectorSource.addFeature(newFeature);
                isGeomProperty = true;
              }
            };

            features.forEach(f => {
              const geometries = Object.keys(f.values_)
                .filter(e => typeof f.values_[e] === 'object' && f.values_[e]?.type && !state.ignoreProperties.includes(e));
              state.ignoreProperties.push(...geometries);
            });

            if (state.ignoreProperties.length > 6) {
              features.forEach(f => {
                state.ignoreProperties.slice(6).forEach(property => {
                  addFeatureIfExists(property, f);
                });
              });
            }

            if (isGeomProperty) {
              features.forEach(f => f.values_.geom_property = 'geom');
            }

            vectorSource.addFeatures(features);
          } else {
            vectorSource.clear();
          }
        } catch (err) {
          log.error('vector load', err);
          dispatch(
            'storeToastMessage',
            {
              text: 'vector load',
              errObj: {
                errors: [
                  { message: `LAYER: ${data.params.LAYERS} ERROR: ${err.response.data.MESSAGE}` }
                ]
              },
              type: 'danger'
            },
            { root: true }
          );
        }
      }
    });

    await dispatch('getLegendVector', {
      layer_name: data.params.LAYERS,
      layer_style: data.params.STYLES
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      style: function (feature) {
        dispatch('getColorFill', [feature.get('standard_property'), feature.get('type_property'), feature.get('geom_property'), data.params.LAYERS]);

        let x = feature.get('standard_property');
        x = Number(String(x).replace(/[^0-9]/g, ''));
        let addition = 0;

        if (feature.get('standard_property') < 1) {
          if (feature.get('standard_property') > -1 && feature.get('standard_property') < 0) {
            addition = 3;
          } else {
            addition = (feature.get('standard_property') == 0) ? 1 : 2;
          }
        } else {
          addition = 1;
        }

        state.selectedFeatureDigits = (Math.log10((x ^ (x >> 31)) - (x >> 31)) | 0) + addition;

        const stroke = new Stroke({ color: state.colorStroke[data.params.LAYERS], width: state.strokeWidth[data.params.LAYERS] });
        const fill = new Fill({ color: state.colorFill[data.params.LAYERS] });
        let padding = [5, 5, 5, 5];

        switch (state.selectedFeatureDigits) {
          case 1:
            padding = [5, 18, 5, 18];
            break;
          case 2:
            padding = [5, 13, 5, 13];
            break;
          case 3:
            padding = [5, 9, 5, 9];
            break;
          default:
            padding = [5, 5, 5, 5];
            break;
        }

        const textStyle = new Text({
          font: 'bold 12px Open Sans',
          textAlign: 'center',
          textBaseline: 'middle',
          fill: new Fill({ color: 'black' }),
          placement: 'point',
          backgroundFill: fill,
          backgroundStroke: stroke,
          padding: padding,
          text: ''
        });

        const virtualStationTextStyle = new Text({
          font: 'bold 24px Open Sans',
          textAlign: 'center',
          textBaseline: 'middle',
          fill: new Fill({ color: 'black' }),
          placement: 'point',
          padding: padding,
          text: ''
        });

        const polygonStyle = new Style({
          stroke: stroke,
          fill: fill,
          zIndex: state.zIndexValue[data.params.LAYERS] || 0
        });

        const circleStyle = new Style({
          image: new Circle({
            radius: 7,
            fill: fill,
            stroke: stroke
          }),
          zIndex: state.zIndexValue[data.params.LAYERS] || 0
        });

        const virtualStationStyle = new Style({
          image: new Circle({
            radius: 50,
            fill: fill,
            stroke: new Stroke({ color: 'black', width: 6 })
          }),
          text: virtualStationTextStyle,
          zIndex: state.zIndexValue[data.params.LAYERS] || 0
        });

        const pointStyle = new Style({
          image: new RegularShape({
            points: 4,
            radius: 10,
            angle: Math.PI / 4
          }),
          text: textStyle,
          zIndex: state.zIndexValue[data.params.LAYERS] || 0
        });

        const text = (feature.get('standard_property') >= 0 || feature.get('standard_property') < 0) ? feature.get('standard_property').toString() : '-';

        if (feature.get('virtual_station')) {
          virtualStationStyle.getText().setText(text);

          return virtualStationStyle;
        }

        if (feature.get('geometry').flatCoordinates.length <= 2) {
          if (feature.get('geom_property') || feature.get('standard_property') >= 0 || feature.get('standard_property') < 0) {
            pointStyle.getText().setText(text);

            return feature.get('geom_property') ? circleStyle : pointStyle;
          } else {
            return circleStyle;
          }
        } else {
          return polygonStyle;
        }
      }
    });

    vectorLayer.set('layer_type', data.TYPE);
    vectorLayer.set('layer_name', data.params.LAYERS);
    vectorLayer.set('layer_style', data.params.STYLES);
    vectorLayer.set('service_id', data.LAYER_SERVICE_ID);
    vectorLayer.set('service_name', data.params.SERVICE_NAME);
    vectorLayer.set('time', data.params.TIME);

    dispatch('checkOpacityServiceID', data.LAYER_SERVICE_ID);

    const opacityValue = state.sliderOpacityValue / 100;
    vectorLayer.setOpacity(opacityValue);
    vectorLayer.setZIndex(1);

    await state.OLmap.addLayer(vectorLayer);

    const vectorState = await vectorSource.getState();
    vectorSource.on('change', async function () {
      if (vectorState === 'ready') {
        for await (const selectedLayer of state.OLmap.getLayers().getArray().slice(1)) {
          if (selectedLayer.values_.time !== data.params.TIME && (selectedLayer.values_.layer_name === data.params.LAYERS && selectedLayer.values_.service_id === data.LAYER_SERVICE_ID)) {
            await state.OLmap.removeLayer(selectedLayer);
          }

          if (selectedLayer.values_.layer_name === data.params.LAYERS && selectedLayer.values_.layer_type === 'REMOVE') {
            await state.OLmap.removeLayer(selectedLayer);
          }
        }
      }
    });
    // state.OLmap.getLayers().getArray()[0].getSource().changed();
  },
  async removePreviousVectorLayer({ state }, data) {
    for await (const layer of state.OLmap.getLayers().getArray().slice(1)) {
      if (data.layer_name !== layer.values_.layer_name && data.service_id === layer.values_.service_id) {
        await state.OLmap.removeLayer(layer);
      }

      if (data.layer_name === layer.values_.layer_name && data.service_id === layer.values_.service_id && data.time === layer.values_.time) {
        await state.OLmap.removeLayer(layer);
      }

      if (data.layer_name === layer.values_.layer_name && data.service_id === layer.values_.service_id && data.time !== layer.values_.time) {
        await state.OLmap.removeLayer(layer);
      }
    }
  },
  async reloadVectorLayerAfterZoom({ state, rootState }) {
    const createFeatureFromGeometry = (geometry, properties) => {
      if (geometry.type === 'Point') {
        return new Feature({
          geometry: new Point(geometry.coordinates),
          ...properties
        });
      }

      if (geometry.type === 'LineString') {
        return new Feature({
          geometry: new LineString(geometry.coordinates),
          ...properties
        });
      }

      if (geometry.type === 'Polygon') {
        return new Feature({
          geometry: new Polygon(geometry.coordinates),
          ...properties
        });
      }

      if (geometry.type === 'MultiPolygon') {
        return new Feature({
          geometry: new MultiPolygon(geometry.coordinates),
          ...properties
        });
      }
    };

    state.OLmap.on('moveend', async () => {
      // state.OLmap.getView().on('change:resolution', async () => {          // listen to zoom changes only
      state.vectorBBOX = state.OLmap.getView().calculateExtent(state.OLmap.getSize());
      const actualZoom = state.OLmap.getView().getZoom();

      if (actualZoom == 2 || actualZoom % 2 != 0 && actualZoom != 3) {
        for await (const layer of state.OLmap.getLayers().getArray().slice(1)) {
          if (layer.values_.layer_type === 'VECTOR') {
            // layer.getSource().refresh();                                 // refresh the source
            const resp = await getVectorLayer(layer.values_.layer_name, state.vectorBBOX, layer.values_.time, rootState.app_user.username, layer.values_.service_name, router.currentRoute.params.id, state.vectorParamRange[layer.values_.service_id]);
            const features = layer.getSource().getFormat().readFeatures(resp.data);
            layer.getSource().clear();

            let isGeomProperty = false;

            const addFeatureIfExists = (property, feature) => {
              if (feature.values_[property]) {
                // eslint-disable-next-line no-unused-vars
                const { geometry, ...values } = feature.values_;
                const newFeature = createFeatureFromGeometry(feature.values_[property], { ...values, geom_property: property });
                layer.getSource().addFeature(newFeature);
                isGeomProperty = true;
              }
            };

            features.forEach(f => {
              const geometries = Object.keys(f.values_)
                .filter(e => typeof f.values_[e] === 'object' && f.values_[e]?.type && !state.ignoreProperties.includes(e));
              state.ignoreProperties.push(...geometries);
            });

            if (state.ignoreProperties.length > 6) {
              features.forEach(f => {
                state.ignoreProperties.slice(6).forEach(property => {
                  addFeatureIfExists(property, f);
                });
              });
            }

            if (isGeomProperty) {
              features.forEach(f => f.values_.geom_property = 'geom');
            }

            layer.getSource().addFeatures(features);
          }
        }
      }
    });
  },
  // ------------------------ GLOBAL LAYERS ---------------------------
  async setGlobalLayer({ dispatch, rootState, state }, globalLayer) {
    const index = rootState.global_layers.selectedGlobalLayer.indexOf(globalLayer);
    const selectedGlobalLayer = rootState.global_layers.selectedGlobalLayer[index] || '';

    if (selectedGlobalLayer !== '') {
      const items = {
        Bucket: S3_BUCKETS.global_layer(),
        Key: `${globalLayer.layer_polygon}`
      };
      const data = [items, globalLayer.color, globalLayer.width, globalLayer.layer_name];

      if (globalLayer.layer_type === 'geojson') {
        await dispatch('openGEOJSON', data);
      }

      if (globalLayer.layer_type === 'kml') {
        await dispatch('openKML', data);
      }

      if (globalLayer.layer_type === 'shp') {
        await dispatch('openSHP', data);
      }

      dispatch('setGlobalLayersInteractions', data);
    } else {
      if (rootState.app_user.defaultService.GLOBAL.customer_layer_id) {
        const CLindex = rootState.app_user.defaultService.GLOBAL.customer_layer_id.findIndex(item => item.id === globalLayer.global_layer_id);

        if (CLindex >= 0) {
          rootState.app_user.defaultService.GLOBAL.customer_layer_id.splice(CLindex, 1);
        }
      }

      if (rootState.app_user.defaultService.GLOBAL.global_layer_id) {
        const GLindex = rootState.app_user.defaultService.GLOBAL.global_layer_id.findIndex(item => item.id === globalLayer.global_layer_id);

        if (GLindex >= 0) {
          rootState.app_user.defaultService.GLOBAL.global_layer_id.splice(GLindex, 1);
        }
      }

      const overlays = state.OLmap.getOverlays();
      overlays.forEach(overlay => {
        if (overlay && overlay.values_.layer_name === globalLayer.layer_name) {
          state.OLmap.removeOverlay(overlay);
        }
      });
      const interactionsToRemove = [];
      const mapInteractions = state.OLmap.getInteractions();
      mapInteractions.forEach(interaction => {
        if (interaction.values_.layer_name === globalLayer.layer_name) {
          interactionsToRemove.push(interaction);
        }
      });
      interactionsToRemove.forEach(e => state.OLmap.removeInteraction(e));
      await dispatch('removeLayer', globalLayer.layer_name);
    }
  },
  async openGEOJSON({ state }, data) {
    const [obj, color, width, layer_name] = data;
    let response = '';

    try {
      const s3_bucket = new AWS.S3();
      response = await s3_bucket.getSignedUrlPromise('getObject', obj);
    } catch (err) {
      log.error('Global Layer err', err);
    }

    if (response) {
      const source = new VectorSource({
        format: new GeoJSON(),
        url: response
      });
      const styles = [new Style({
        image: new Circle({
          radius: width,
          fill: new Fill({ color: color }),
          stroke: new Stroke({ color: color, width: 1 })
        })
      }),
      new Style({
        stroke: new Stroke({
          color: color || '',
          width: width || ''
        })
      })];

      const vector = new VectorLayer({
        source: source,
        style: styles
      });

      vector.set('layer_type', 'GLOBAL LAYER');
      vector.set('layer_name', layer_name);
      state.OLmap.addLayer(vector);
      state.OLmap.getLayers().getArray()[1].getSource().changed();
    }
  },
  async openKML({ dispatch, state }, data) {
    const [obj, color, width, layer_name] = data;
    const s3_bucket = new AWS.S3();
    const response = await s3_bucket.getSignedUrlPromise('getObject', obj);

    if (response) {
      const vector = new VectorLayer({
        source: new VectorSource({
          url: response,
          format: new KMLSource({
            extractStyles: false
          })
        }),
        style: [new Style({
          image: new Circle({
            radius: width,
            fill: new Fill({ color: color }),
            stroke: new Stroke({ color: color, width: 1 })
          })
        }),
        new Style({
          stroke: new Stroke({
            color: color,
            width: width
          })
        })]
      });
      vector.set('layer_type', 'GLOBAL LAYER');
      vector.set('layer_name', layer_name);
      state.OLmap.addLayer(vector);
    } else {
      dispatch(
        'storeToastMessage',
        {
          text: 'KML Layer Link failed',
          type: 'error'
        },
        { root: true }
      );
    }
  },
  async openSHP({ state, dispatch }, data) {
    const [obj, color, width, layer_name] = data;
    const s3_bucket = new AWS.S3();
    const response = await s3_bucket.getSignedUrlPromise('getObject', obj);

    if (response) {
      shp(response).then(async function (geojson) {
        const source = new VectorSource();
        const vector = new VectorLayer({
          source: source,
          style: [new Style({
            image: new Circle({
              radius: width,
              fill: new Fill({ color: color }),
              stroke: new Stroke({ color: color, width: 1 })
            })
          }),
          new Style({
            stroke: new Stroke({
              color: color,
              width: width
            })
          })]
        });

        vector.set('layer_type', 'GLOBAL LAYER');
        vector.set('layer_name', layer_name);
        await state.OLmap.addLayer(vector);
        await source.addFeatures(new GeoJSON({ featureProjection: 'EPSG:3857' }).readFeatures(geojson));
        state.OLmap.getLayers().getArray()[1].getSource().changed();
      });
    } else {
      dispatch(
        'storeToastMessage',
        {
          text: 'Error Link failed',
          type: 'error'
        },
        { root: true }
      );
    }
  },
  setGlobalLayersInteractions({ state }, data) {
    // eslint-disable-next-line no-unused-vars
    const [obj, color, width, layer_name] = data;

    const overlay = new Overlay({
      positioning: 'center-left',
      offset: [10, 0],
      element: document.createElement('div')
    });
    state.OLmap.addOverlay(overlay);

    const interactionStyles = [new Style({
      image: new Circle({
        radius: width + 5,
        fill: new Fill({ color: color }),
        stroke: new Stroke({ color: color, width: 1 })
      })
    }),
    new Style({
      stroke: new Stroke({
        color: color,
        width: width + 5
      })
    })];

    const clickInteraction = new Select({
      layers: function (layer) {
        return layer.values_.layer_name === layer_name && !state.isVectorPopup;
      },
      condition: singleClick,
      style: interactionStyles
    });

    const hoverInteraction = new Select({
      layers: function (layer) {
        return layer.values_.layer_name === layer_name && !state.isVectorPopup;
      },
      condition: pointerMove,
      style: interactionStyles
    });

    state.OLmap.on('click', function (event) {
      state.OLmap.forEachFeatureAtPixel(event.pixel, function (feature, layer) {
        state.isVectorPopup = layer.values_.layer_type === 'VECTOR';

        return true;
      });
    });

    clickInteraction.on('select', (event) => {
      const selectedFeatures = event.target.getFeatures();

      if (selectedFeatures.getLength() > 0 && !state.isVectorPopup) {
        const selectedFeature = selectedFeatures.item(0);
        const properties = selectedFeature.getProperties();
        let isFirstItem = true;
        const props = Object.keys(properties).length > 1 ? Object.keys(properties).map(item => {

          if (item !== 'geometry') {
            const result = (isFirstItem ? '' : '<br />') + `<span><b>${item}:</b> ${properties[item]}</span>`;
            isFirstItem = false;

            return result;
          }

          return '';
        }) : ['no data'];

        overlay.setPosition(event.mapBrowserEvent.coordinate);
        overlay.getElement().innerHTML = `
          <style>
            .overlay-style {
              color: white;
              font: 12px Open Sans;
              padding: 5px 10px;
              background-color: rgba(0, 0, 0, 0.7);
              border: 1px solid green;
              border-radius: 5px;
              z-index: 100;
              max-height: 500px;
              max-width: 300px;
              overflow-y: auto;
            }
          </style>
          <div class="overlay-style">${props.join('')}</div>`;
        overlay.getElement().style.display = 'block';
      } else {
        overlay.getElement().style.display = 'none';
      }
    });

    overlay.set('layer_name', layer_name);
    clickInteraction.set('layer_name', layer_name);
    hoverInteraction.set('layer_name', layer_name);

    state.OLmap.addInteraction(clickInteraction);
    state.OLmap.addInteraction(hoverInteraction);
  },
  // ------------------------ LEGEND ---------------------------
  async getLegendVector({ state, dispatch }, data) {
    try {
      state.legend_vectorRules[data.layer_name] = [];
      const legendArray = await getLegendValues(data.layer_name, data.layer_style);

      state.legend_vector = legendArray.data.Legend[0].rules;
      state.legend_vector.forEach((item, index) => {
        if (item.symbolizers[0].Point) {
          const color = item.symbolizers[0].Point.graphics[0].fill;
          const stroke = item.symbolizers[0].Point.graphics[0].stroke ?? 'black';
          const strokeWidth = item.symbolizers[0].Point.graphics[0]['stroke-width'];
          const [lowerBound, operator, upperBound] = item.name.split(',');
          state.legend_vectorRules[data.layer_name].push({
            lowerBound: lowerBound,
            operator: operator,
            upperBound: upperBound || '',
            color: color,
            stroke: stroke,
            strokeWidth: strokeWidth || 2,
            filter: (item.filter) ? item.filter.split('\'')[1] : '',
            zIndex: item.abstract?.zIndex || index
          });
        }

        if (item.symbolizers[0].Polygon) {
          const color = item.symbolizers[0].Polygon.fill;
          const stroke = item.symbolizers[0].Polygon.stroke ?? 'black';
          const strokeWidth = item.symbolizers[0].Polygon['stroke-width'];
          const [lowerBound, operator, upperBound] = item.name.split(',');
          state.legend_vectorRules[data.layer_name].push({
            lowerBound: operator ? lowerBound : item.filter.split('\'')[1],
            operator: operator || '=',
            upperBound: upperBound || '',
            color: color,
            stroke: stroke,
            strokeWidth: strokeWidth || 2,
            zIndex: item.abstract?.zIndex || index
          });
        }

        if (item.symbolizers[0].Line) {
          const color = item.symbolizers[0].Line.stroke;
          const strokeWidth = item.symbolizers[0].Line['stroke-width'];
          const [lowerBound, operator, upperBound] = item.name.split(',');
          state.legend_vectorRules[data.layer_name].push({
            lowerBound: operator ? lowerBound : item.filter.split('\'')[1],
            operator: operator || '=',
            upperBound: upperBound || '',
            color: color,
            stroke: color,
            strokeWidth: strokeWidth || 2,
            zIndex: item.abstract?.zIndex || index
          });
        }
      });

      if (state.legend_vectorRules[data.layer_name].some(e => e.lowerBound === 'geom_property')) {
        state.legend_vectorRules[data.layer_name].forEach(e => {
          e.geomType = e.operator;
          e.operator = '<';
          e.lowerBound = '1000';
        });
      }
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'getLegendVector',
          errObj: {
            errors: [{ message: 'Error while fetching legend. Reload the Vector Layer.' }]
          },
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  getColorFill({ state }, [standard_property, type_property, geom_property, layer_name]) {
    if (geom_property) {
      const vectorRule = state.legend_vectorRules[layer_name].find(r => r.geomType === geom_property);
      state.colorFill[layer_name] = vectorRule.color;
      state.colorStroke[layer_name] = vectorRule.stroke;
      state.strokeWidth[layer_name] = vectorRule.strokeWidth;
      state.zIndexValue[layer_name] = vectorRule.zIndex;
    } else {
      state.legend_vectorRules[layer_name].forEach(rule => {
        if (type_property >= 0 && type_property == rule.filter) {
          state.colorFill[layer_name] = rule.color;
          state.colorStroke[layer_name] = rule.stroke;
          state.strokeWidth[layer_name] = rule.strokeWidth;
          state.zIndexValue[layer_name] = rule.zIndex;
        }

        if (standard_property || standard_property === 0) {
          if (rule.upperBound !== '') {
            if (rule.operator === '<=' || rule.operator === '<') {
              if (standard_property > rule.lowerBound && `${standard_property} ${rule.operator} ${rule.upperBound}`) {
                state.colorFill[layer_name] = rule.color;
                state.colorStroke[layer_name] = rule.stroke;
                state.strokeWidth[layer_name] = rule.strokeWidth;
                state.zIndexValue[layer_name] = rule.zIndex;
              }
            }
          } else {
            if (rule.operator === '<' || rule.operator === '>' || rule.operator === '=') {
              if (rule.operator === '=') {
                if (standard_property == rule.lowerBound) {
                  state.colorFill[layer_name] = rule.color;
                  state.colorStroke[layer_name] = rule.stroke;
                  state.strokeWidth[layer_name] = rule.strokeWidth;
                  state.zIndexValue[layer_name] = rule.zIndex;
                }
              } else {
                if (`${standard_property} ${rule.operator} ${rule.lowerBound}`) {
                  state.colorFill[layer_name] = rule.color;
                  state.colorStroke[layer_name] = rule.stroke;
                  state.strokeWidth[layer_name] = rule.strokeWidth;
                  state.zIndexValue[layer_name] = rule.zIndex;
                }
              }
            }
          }
        } else {
          if (type_property == undefined) {
            state.colorFill[layer_name] = 'white';
            state.colorStroke[layer_name] = 'black';
            state.strokeWidth[layer_name] = 2;
            state.zIndexValue[layer_name] = 0;
          }
        }
      });
    }

    if (Array.isArray(state.colorFill[layer_name])) {
      state.colorFill[layer_name] = state.colorFill[layer_name][0];
    }

    if (Array.isArray(state.colorStroke[layer_name])) {
      state.colorStroke[layer_name] = state.colorStroke[layer_name][0];
    }
  }
};
